import React, { useEffect, useRef } from "react"
import { navigate } from "gatsby"
import { useLocation } from "@reach/router"

export default function PageTransition({ oldPathname, children }) {
  const { pathname } = useLocation()
  const patternWrapperRef = useRef(null)

  useEffect(() => {
    const body = document.body
    const patternWrapper = patternWrapperRef.current

    if (!patternWrapper) return

    function handlePageExit(event, path) {
      event.preventDefault()

      const isMenuOpen = body.classList.contains("menu-open")
      if (pathname === path) return body.classList.remove("menu-open")

      patternWrapper.style.zIndex = 30
      patternWrapper.style.opacity = 1
      body.classList.remove("menu-open")

      setTimeout(
        () => {
          patternWrapper.classList.remove("page-load")
          patternWrapper.classList.add("page-exit")
        },
        isMenuOpen ? 850 : 650
      )

      setTimeout(() => navigate(path), isMenuOpen ? 1500 : 1300)
    }

    function handlePageLoad() {
      window.scrollTo(0, 0)
      patternWrapper.classList.remove("page-exit")
      patternWrapper.classList.add("page-load")
      setTimeout(() => body.classList.remove("menu-open"), 1400)
    }

    if (oldPathname === null) {
      patternWrapper.style.transform = `translateY(100%)`
      patternWrapper.style.zIndex = -1
      patternWrapper.style.opacity = 0
    } else {
      patternWrapper.style.transform = ``
      patternWrapper.style.zIndex = 30
      patternWrapper.style.opacity = 1
      handlePageLoad()
    }

    const links = document.querySelectorAll(`a:not([target="_blank"]):not([href^="mailto:"])`)

    links.forEach(link => {
      const path = link.getAttribute("href")
      link.addEventListener("click", e => handlePageExit(e, path))
    })

    return () => {
      links.forEach(link => {
        const path = link.getAttribute("href")
        link.removeEventListener("click", e => handlePageExit(e, path))
      })
    }
  }, [oldPathname, pathname])

  return (
    <>
      {children}
      <div ref={patternWrapperRef} className='pattern-panel' />
    </>
  )
}
